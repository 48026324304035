import { ComponentRef } from '@wix/editor-platform-sdk-types';
import { EditorAppContext } from './types';
import { getAppWidgetWrappedSearchButtonDefinition } from './componentDefinitions';
import { searchButtonAddedSrc99Evid411 } from '@wix/bi-logger-wix-code-data/v2';

export async function isSearchButton(
  appContext: EditorAppContext,
  componentRef: ComponentRef,
): Promise<boolean> {
  const { appDefinitionId, editorSDK, reportError } = appContext;
  try {
    const componentType = await editorSDK.components.getType(appDefinitionId, {
      componentRef,
    });
    return componentType === 'wixui.SearchButton';
  } catch (error) {
    reportError(error);
  }
  return false;
}

export async function addSearchButton(
  appContext: EditorAppContext,
): Promise<ComponentRef | undefined> {
  const { editorSDK, appDefinitionId, reportError } = appContext;
  const headerRef = await editorSDK.siteSegments.getHeader(appDefinitionId);

  const searchButtonLayout = {
    width: 42,
    height: 42,
    x: 0,
    y: 0,
    scale: 1,
    rotationInDegrees: 0,
    fixedPosition: false,
  };
  const componentDefinition = getAppWidgetWrappedSearchButtonDefinition({
    appContext,
    layout: searchButtonLayout,
  });
  try {
    const searchButton = await editorSDK.components.add(appDefinitionId, {
      pageRef: headerRef,
      componentDefinition,
    });
    appContext.flowAPI.bi?.report(
      searchButtonAddedSrc99Evid411({ button_type: 'searchButton' }),
    );
    return searchButton;
  } catch (error) {
    reportError(error);
  }
}
